/****  Footer  ****/

#myBtn {
  display: none;
  position: fixed;
  bottom: 11px;
  right: 11px;
  z-index: 99;
  font-size: 18px;
  border: none;
  outline: none;
  background-color: $bg-color-black;
  color: white;
  cursor: pointer;
  padding: 11px;
  border-radius: 4px;
  box-shadow: white -1px -1px 5px;
}

#myBtn:hover {
  background-color: #555;
}

pre {
  font-family: "Courier";
  color: black;
  font-weight: bold;
  font-size: 1.1em;
  line-height: 18px;
  width: 100%;
  white-space: pre-wrap;
  white-space: -moz-pre-wrap;
  white-space: -pre-wrap;
  white-space: -o-pre-wrap;
  // word-wrap: break-word;
}

.img-wrapper {
  max-height: 420px;
  overflow: hidden;

  img.card-img-top {
    // min-height: 380px;
  }
}

p.card-text {
  font-size: 17px;
}


footer {
  padding-bottom: 15px;
  background-color: $menu-footer-button;

  .empty {
    // padding-top: 20px;
  }

  .copyright {
    color: #17a2b8b5;
  }
}
