/**** Carousel Slideshow on Home page ****/
$indicator-width: 16.666%;

#homeSlideShow .carousel-inner {
  min-height: 630px;
}

.carousel-inner {
  img.w-100 {
    width: $width-80;
    // margin-left: 5%;
  }

  .card-body {
    min-height: 330px;

    p {
      font-size: 17px;
    }
  }

  .btn {
    position: absolute;
    bottom: 20px;
  }
}

.container-fluid {
  padding-right: 0;
}

.carousel-indicators {
  // display: none;
  width: $width-80;
  position: absolute;
  margin-left: 0;
  margin-right: 0;
  // right: 0;
  // bottom: 0;
  // left: 0;
  z-index: 15;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  // padding-left: 0;
  // margin-right: 0;
  list-style: none;
  margin-bottom: -11px;

  // margin-bottom: -35px;
  // li.active {
  //     // display: none;
  // }
  li {
    -webkit-box-sizing: content-box;
    box-sizing: content-box;
    -webkit-box-flex: 0;
    -ms-flex: 0 1 auto;
    flex: 0 1 auto;
    width: $indicator-width;
    height: auto;
    margin-right: 0;
    margin-left: 0;
    text-indent: 0;
    cursor: pointer;
    background-color: #000;
    background-clip: padding-box;
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    // opacity: .5;
    opacity: 1;
    -webkit-transition: opacity 0.6s ease;
    transition: opacity 0.6s ease;
  }

  .thumb-wrapper {
    .img-thumbnail {
      padding: 0;
      background-color: none;
      border: none;
      border-radius: 0;
      // max-width: 100%;
      width: 100%;
      height: auto;
    }

    .thumb-text {
      position: relative;
      display: block;
      width: 100%;
      background-color: #000000b3;
      color: #FFF;
      font-family: $font-open-sans;
      /* font-size: 21px; */
      line-height: 26px;
      min-height: 120px;
      max-height: 131px;
      margin-top: -120px;
      padding: 20px 20px;
      transition: 0.6s;
      // vertical-align: middle;
    }

    h4 {
      display: inline-block;
      max-width: 250px;
      padding: 2% 2%;
      font-size: 20px;
      font-weight: 400;
      line-height: 1.3;
      // &.shakespeare {
      //     padding: 2% 2%;
      //     max-width: 288px;
      // }
    }
  }

  .sub-thumb-wrapper {
    display: none;
    position: absolute;
    bottom: 10px;
    width: $indicator-width;

    &.sub-scripts {
      // right: 40%;
    }

    &.sub-favorites {
      // right: 20%;
    }
  }

  a:hover {
    .thumb-text {
      background-color: #093c44;
    }
  }
}


#homeSlideShow {
  min-height: 371px;
  border-bottom: 7px solid black;


  .carousel-control-prev,
  .carousel-control-next {
    width: 5%;
  }

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    opacity: 0.5;
    width: 30px;
    height: 30px;
  }

  .carousel-control-next-icon {
    // text-align: right;
    // margin-left: 20px;
  }

  // .toggle-show-hide {
  //   display: block;
  // }

  // .toggle-show-hide.down {
  //   display: none ;
  // } 

  .thumb-wrapper {
    border: 1px solid $border-color;
    // border-right: 2px solid $border-color;
    // border-left: 2px solid $border-color;
  }
}


.carousel-caption {
  position: absolute;
  right: 15%;
  top: 22%;
  right: 0;
  bottom: auto;
  left: auto;
  z-index: 16;
  color: #fff;
  max-width: 543px;
  height: auto;
  // padding: 35px 4%;
  padding: 30px 3%;
  text-align: left;
  padding-right: 4%;

  font-family: $font-menu-item;

  &.slide1 {
    background-color: $bg-marc;
  }

  &.slide2 {
    background-color: $bg-vivaldi;
  }

  &.slide3 {
    background-color: $bg-shakespeare;
  }

  &.slide4 {
    background-color: $bg-cold;
  }

  &.slide5 {
    background-color: $bg-hertzog;
  }

  &.slide6 {
    background-color: $bg-whn;
  }

  a {
    color: white;

    &:hover {
      .read-more {
        padding-left: 4px;
      }
    }
  }

  .count-number {
    display: inline-block;
    height: 20px;
    width: 100%;
    font-size: 14px;
    letter-spacing: 1.56px;
    line-height: 20px;

    &::first-letter {
      color: #ffb4aa;
      font-weight: bold;
      font-size: 2em;
      vertical-align: top;
    }
  }

  h1 {
    display: inline-block;
    // height: 160px;
    // max-width: 391px;
    font-size: 250%;
    line-height: 50px;
    margin-top: 15px;
    margin-bottom: 15px;
  }

  .teaser {
    height: auto;
    // max-width: 391px;
    font-family: $font-open-sans;
    font-size: 16px;
    line-height: 25px;
    margin-top: 5px;
    text-align: justify;
  }

  .read-more {
    height: auto;
    max-width: 491px;
    font-size: 14px;
    // letter-spacing: 1px;
    line-height: 50px;
    transition: 0.5s;
  }

  .read-text {
    display: inline;
  }

  .right-arrow {
    display: inline-block;
    margin-left: 19px;
    height: 15px;
    width: 50px;
    background-image: url(../../images/icons/arrow-r.png);
    background-size: 45px 10px;
    background-repeat: no-repeat;
    background-position: 0 5px;
  }
}

.container-fluid {
  .container-inner {
    padding: 3%;
    // width: 31%;
  }
}
