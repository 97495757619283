/**** Mobile and Responsive  ****/

.navbar-collapse .nav-item>ul.mobile-submenu {
  display: none;
}

@media screen and (min-width: 1440px) {
  .carousel-caption {
    max-width: 35%;
  }
}

@media screen and (max-width: 1199px) {
  .carousel-caption {
    padding: 22px 4%;

    h1 {
      // height: 50px;
      width: 100%;
      font-size: 180%;
      line-height: 40px;
      // margin-top: 5px;
    }

    .read-more {
      height: 65px;
    }
  }

  .thumb-wrapper {
    h4 {}
  }

  .sub-page {
    height: 58%;
  }
}

@media screen and (max-width: 1000px) {
  .sub-page {
    height: 60%;
  }

  .carousel-indicators .thumb-wrapper .thumb-text {
    padding: 10px;
  }
}

@media screen and (max-width: 769px) {

  .btn-language  {
    right: 120px;
  }

  /*** Top Menu ***/
  
  #homeSlideShow .carousel-inner {
    min-height: 600px;
  }


  .navbar .navgar-collapse .row {
    margin-right: 0;
    margin-left: 0;
    padding-top: 34px;
  }

  .barnd-navbar-icon {
    position: relative;
    height: 66px;
    width: 100%;
  }

  .navbar-toggler {
    float: right;
    margin-right: 5%;
    padding: 0;

    .navbar-toggler-icon {
      background-image: url(../../images/icons/nav-m.png);
      width: 45px;
      height: 43px;
      padding-top: 14px;
    }

    .navbar-toggler-icon.opened {
      width: 60px;
      height: 63px;
    }
  }

  #collapsingNavbar {
    padding-left: 8%;
    height: auto;
    padding-bottom: 90px;
    background-position: -22px 0;
    background-size: cover;
  }

  .nav-item {
    min-height: 48px;
    padding: 0;

    a {
      font-size: 21px;
    }
  }

  .menu-hover-right {
    display: none;
  }

  .navbar-light .navbar-nav .nav-link:hover,
  .navbar-light .navbar-nav .nav-link:focus,
  .navbar-light .navbar-nav .nav-link.show,
  .navbar-light .navbar-nav .nav-link.active {
    padding-left: 28px;
  }

  /***END top menu***/
  .carousel-indicators {
    width: 100% !important;
    margin: 0;
    padding: 0;
    bottom: -20px;

    .thumb-wrapper h4 {
      display: inline-block;
      max-width: 145px;
      padding: 0;
      font-size: 16px;
    }

    .thumb-wrapper .thumb-text {
      line-height: 26px;
      min-height: 99px;
      margin-top: -32px;
      padding: 12px 15px;
      max-height: 114px;
      overflow: hidden;
    }
  }

  .right-side-bar {
    display: none;
  }

  .carousel-inner img.w-100 {
    width: 100% !important;
    margin-left: 0;
  }

  .carousel-caption {
    padding: 18px 4%;
    top: 7%;

    // h1 {
    //     height: 50px;
    //     width: 100%;
    //     font-size: 220%;
    //     line-height: 70px;
    //     margin-top: 5px;
    // }
    .read-more {
      height: 55px;
      line-height: 60px;
    }
  }

  .thumb-wrapper {
    h4 {
      font-size: 16px;
      // line-height: 1.2;
    }
  }

  .mobile-submenu {
    list-style: none;
    display: none;
  }

  .jumbotron {
    background-attachment: initial;
    background-size: 111%;
  }

  .main-content {
    padding: 2% 8%;
  }

  /*** Script Mobile  ***/
  .hero {
    // height: 120px;
    min-height: 100px;
    max-height: 120px;
    // margin-top: -90px;

    .container {
      // width: 84%;
      // height: 120px;
    }

    .title {
      font-size: 1.6em;
      // padding-top: 16px;
    }
  }

  // .read-script {
  //   font-size: 16px;
  // }

  .pt-4,
  .py-4 {
    padding-top: 0.7rem !important;
  }

  article {
    font-size: 16px;

    h2 {
      font-size: 3rem;
    }

    pre {
      font-size: 14px;
    }
  }

  .sub-page {
    // height: 32%;
    // height: 350px;
    width: 6%;
  }


  .hero.bg-marc {
    position: relative;

    .eldor-sub-alink {
      position: absolute;
      top: 11px;
      display: inline-block;
      right: 0;
    }
  }


  .bg-marc {
    .top-title {
      font-size: 22px;
      margin-top: 30%;
    }
  }
}
/***END Script Mobile***/

@media screen and (max-width: 480px) {

  h2 {
    font-size: 1.4rem;
  }

  .card-body h5 {
    font-size: 1.75rem;
  }

  #collapsingNavbar {
    padding-top: 90px;

    .nav-link {
      padding: 0;
    }
  }

  #homeSlideShow {

    border-bottom: none;
    /* min-height: 1013px; */
    margin-bottom: 81%;

    .container-fluid {
      margin-top: auto;
      padding-right: 0;
    }
  }


  .container-fluid {
    margin-top: 80px;
    padding-right: 15px;


  }


  .img-wrapper {
    height: 350px;
  }

  .carousel-indicators {
        position: absolute;
        bottom: -315px;

    li.col-sm-6 {
      width: 33%;
      margin: -20px 0 0 0;
      padding: 0;

      .thumb-wrapper {
        .thumb-text {
          height: 62px;
          margin-top: -50px;
          padding: 11px;
        }

        h4 {
          max-width: 100%;
          padding: 0 8px;
          // font-size: 17px;
        }
      }

      .sub-thumb-wrapper {
        width: 100% !important;
      }
    }

    // li.col-sm-6.last {
    //     width: 100%;
    //     height: 200px;
    //     overflow: hidden;
    //     .thumb-wrapper .thumb-text {
    //         position: absolute;
    //         margin-top: 0;
    //         top: 136px;
    //     }
    // }
  }

  .carousel-caption {
    position: inherit;
    // margin: -70px 5% 32% 5%;
    margin: -46% 3% 2% 3%;
    right: 0;

    .count-number {
      height: 16px;
      font-size: 16px;
      line-height: 13px;
    }

    h1 {
      // height: 28px;
      font-size: 27px;
      line-height: 33px;
    }

    .teaser {
      // height: 32px;
      font-size: 15px;
      line-height: 22px;
      margin-top: 0px;
    }

    .read-more {
      font-size: 13px;
      letter-spacing: 2px;
      // margin-left: 40%;
      // margin-top: 25px;
      // padding-top: 5px;

    }

    .right-arrow {
      margin-left: 15px;
      // height: 9px;
      width: 42px;
      background-size: 40px 9px;
      background-repeat: no-repeat;
    }
  }

  .right-side-bar {
    display: none;
  }

  #myBtn {
    right: 3px;
  }

  .carousel-control-prev,
  .carousel-control-next {
    position: absolute;
    top: 103px;
    bottom: auto;
    z-index: 19;
    opacity: 0.6;
  }

  #homeSlideShow .carousel-control-prev-icon,
  #homeSlideShow .carousel-control-next-icon {
    opacity: 1;
    width: 27px;
    height: 27px;
  }

  /*** Script Mobile  ***/
  .jumbotron {
    background-attachment: initial;
    background-size: 125%;

    &.bg-script-vivaldi {
      background-size: 186%;
    }
  }

  .hero {
    height: 85px;

    // margin-top: -106px;
    .container {
      width: 84%;
      // height: 78px;
      min-height: none;
    }

    .title {
      // height: 40px;
      font-size: 1.1em;
      line-height: 28px;
      padding-top: auto;
    }
  }

  .hero.bg-shakespeare {
    min-height: 100px;
  }

  .read-script {

    // font-size: 12px;
    // img.arrow-down {
    //   width: 24px;
    //   height: auto;
    //   // margin-top: -8px;
    // }

    .vertical-text {
      
    }
  }

  article {
    font-size: 16px;

     h2 {
      font-size: 1.8rem;
    }

    pre {
      font-size: 16px;
    }
  }

  .sub-page {
    height: 25%;
  }


  .container-inner {

    .btn {
      position: relative;
      bottom: 0;
      margin-top: 10px;
      margin-bottom: 10px;
    }

    .card-body {
      min-height: 300px;
    }
  }

  .bg-marc {
    .top-title {
      margin-top: 35%;
    }
  }

  
  .bg-marc .top-title {
    margin: 35% 0 9% 0;
  }

  .main-content {
    padding: 2% 3%;
  }
}
/**END 480px **/

@media screen and (max-width: 560px) {

  p.youtube,
  img.certificate {
    width: 100%;
  }

  h3.d8 {
    font-size: 1.3em;

  }
}

@media screen and (max-width: 480px) {
  #homeSlideShow .carousel-inner {
    min-height: 540px;
  }
}

@media screen and (max-width: 426px) {
  #homeSlideShow .carousel-inner {
    min-height: 500px;
  }
}

// @media screen and (max-width: 400px) {
//   #homeSlideShow {
//     min-height: 1085px;
//   }
// }

// @media screen and (max-width: 360px) {
//   #homeSlideShow {
//     min-height: 1013px;
//   }
// }

@media screen and (max-width: 320px) {
  #homeSlideShow .carousel-inner {
    min-height: 390px;
  }

  .card {
    width: 95%;
  }
}
