body {
  background: $bg-color;
  font-size: 18px;
}

main {
  background-color: #aa6d2c1c;
  // background-image: url("../../images/marcBio.jpg");
  // background-repeat: no-repeat;
  // min-height: 1024px;
  // -webkit-background-size: cover;
  // -moz-background-size: cover;
  // -o-background-size: cover;
  // background-size: 100%;
  // background-size: cover;
}

a {
   transition: 0.3s;
}

.main-content {
  padding: 2% 8%;
}


.right-side-bar {
  display: block;

  position: absolute;
  left: 0;
  width: 5%;
  // float: right;
  // height: 69.7%;
  height: 76.3%;
  // background-color: #00000070;
  // background-image: linear-gradient(to right, #aa6d2c1c, #B45F06a8);

  .right-collapse-menu {
    position: absolute;
    bottom: 31px;
    left: 5%;
    opacity: 0.5;
    z-index: 18;

    img {
      width: 100%;
    }
  }
}

.clearfix {
  padding: 30px;
}


.accordion {
  a {
    color: #5997af;
    font-size: 0.7em;
    font-weight: 500;
  }

  .card-header {
    background-color: white;

    &.bg-youtube-header {
      background-color: #17a2b8;
      h2 {
        color: white;
      }
    }
  }
}

a.play-youtube-img {
  position: relative;

  .img-thumbnail {
    padding: 0;
    background-color: none;
    border: 1px solid rgba(250, 127, 51, 0.562);
    box-shadow: 5px 5px 6px #fa7e3394;
    border-radius: 10px;
    max-width: 350px;
    transition: 0.6s;
  }

  .player-button {
    position: absolute;
    left: 40%;
    // margin-top: 120px;
    top: -45px;
    width: 100px;
    border: 2px solid #ed000045;
    box-shadow: 2px 2px 20px white;
    border-radius: 60px;
    background-color: #ffffff91;
    transition: 0.6s;
  }

  &:hover {
    .player-button {
      border: 15px solid #fa7e3380;
    }
    .img-thumbnail {
      border: 1px solid white;
      box-shadow: 5px 5px 6px white;
      
    }
  }
}

.btn-language {
  display: inline-block;
  max-width: 70px;

  top: 0;
  right: 0;
  left: auto;
  z-index: 20;

  a.language {
    color: white;
    background-color: #138496;
    opacity: 0.8;
    font-size: 18px;
    font-weight: bold;
    border-radius: 1px;
    margin-right: auto;
    padding: 9px 7px;
    display: inline-block;
    height: 43px; 
    min-width: 45px;
    margin-top: 2px;
  
  }
}

.btn:hover {
  text-decoration: none;
}