/****  branc-navbar and navbar  ****/

.navbar {
  padding: 0;
  z-index: 20;


}

button:focus {
  outline: none;
}

.navbar-toggler {
  .navbar-toggler-icon {
    background-image: url(../../images/icons/nav.png);
    width: 78px;
    height: 94px;
    transition: 0.5s;

    &:hover {
      border: 2px solid #a7c9d8;
    }
  }
}

.navbar-toggler {
  border: none !important;
  padding: 0.25rem 0 0.25rem 0.75rem;

  .navbar-toggler-icon.opened {
    background-image: url(../../images/icons/nav-x.png);
    width: 78px;
    height: 99px;
  }
}

.barnd-navbar-icon {
  display: inline-block;
  position: absolute;
  top: 0;
  left: 0;
  background-color: #c1dde4db;
  transition: background-color 100ms linear;
  // padding-left: 20px;
  padding: 10px 10px 10px 0;
  z-index: 10;

  a {
    font-family: $font-menu-item;
    color: black;
  }

  a.navbar-brand {
    margin-right: 0;
    margin-left: 0.2rem;
  }

  a.navbar-brand:hover {
    color: $color-hover;
    transition: color 300ms linear;
    // text-decoration: underline;
    border-bottom: 3px solid currentColor;
  }
}

.barnd-navbar-icon.opened {
  background-color: rgba(36, 36, 36 , 0.3);
  transition: background-color 300ms linear;

  a {
    color: white;
  }
}

.nav-item {
  min-height: 48px;
  // padding-top: 12px;
  // padding-bottom: 11px;

  &:hover {

    // display: block;
    a {
      @extend %tranEffect;
    }
  }

  a {
    max-width: 548px;
    color: #FFF;
    font-family: $font-menu-item;
    font-size: $font-size-menu;
    letter-spacing: 0;
    display: inline-block;
    padding-left: 10px;
  }

  a.shakespeare {
    line-height: 46px;
  }

  >.dropdown-menu {
    position: absolute;
  }
}

.menu-hover-right {
  ul {
    list-style-type: none;
    margin-top: 20px;
    padding: 0;
  }

  .nav-item {

    height: auto;
    max-height: 62px;
    padding-bottom: 0;

    &.active,
    &:hover {
      border-bottom: 3px solid $color-hover;
      color: $color-hover;

      a {
        @extend %tranEffect;
      }
    }

    a {
      font-size: 0.8em;
      padding-left: 0;
    }
  }
}

.scripts,
.favorites {
  img.menu-img {
    max-height: 240px;
    overflow: hidden;
  }

}

.navbar-light .navbar-nav .show>.nav-link {
  color: $color-hover;
  padding-left: 10px;
}

.navbar-light .navbar-nav .nav-link {
  color: white;

  /*
.rectangle {
    display: inline-block;
    height: 224px;
    width: 376px;
    position: absolute;
    z-index: -30;
    top: 0;
    right: 0;
    background-color: #34697F;
    transition: all 1s;
}
*/
  &:hover,
  &:focus,
  &.show,
  &.active {
    color: $color-hover;

    &::before {
      content: "| ";
      margin-left: -14px;
    }

    /*
    .rectangle {
        display: inline-block;
        height: 30px;
        width: 3px;
        position: relative;
        background-color: #34697F;
        transform: scaleX(-1) rotate(-360deg);
        z-index: 40;
    }
    */
  }
}

// .navbar-light .navbar-nav .rectangle {
//     height: 30px;
//     width: 3px;
//     background-color: #34697F;
//     transform: scaleX(-1) rotate(-360deg);
// }
#collapsingNavbar {
  position: absolute;
  width: 100%;
  left: 0;
  top: 0;
  z-index: 4;
  // background-color: $bg-color-black;
  background-color: #232323f2;
  // background-position: -20px -20px;
  background-size: cover;
  background-repeat: repeat-y;
  padding-bottom: 8%;
  padding-top: 13%;
  padding-left: 9%;
  // height: 1024px;
  height: auto;
  .row {
    margin: 0;
  }

}

.menu-img {
  max-width: 380px;
  border: 2px solid $color-hover;
  // padding: 1px;
}

.hover-box-wapper {
  display: none;
  position: absolute;
  top: 0;
  z-index: 0;
  
  .nav-link {

    &:hover,
    &:focus,
    &.show,
    &.active {
      color: $color-hover;
    }
  }
}

.hover-box-wapper.scripts {
  .menu-img {
    display: none;
  }

  .menu-img.active {
    display: block;
  }
}

.hover-box-wapper.favorites {
  .menu-img {
    display: none;
  }

  .menu-img.active {
    display: block;
  }
}

.hover-box-wapper.send-to-bottom {
  z-index: -10px;
}
