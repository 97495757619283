/****  Script/Article fo Single page ****/
.banner {
  height: auto;
  /* Flexbox */
  display: flex;
  align-items: center;

  .clearfix {
    min-height: 500px;
  }
}

.hero {
  display: block;
  height: 156px;
  width: 100%; 
  // margin-top: -156px;
  // margin-top: -100px;
  margin-top: -49px;
  // z-index: 10;
      padding-top: 12px;
      padding-bottom: 12px;

  .title {
    display: block;
    // height: 80px;
    width: 100%;
    color: #FFF;
    font-size: 2.2rem;
    // line-height: 80px;
    // padding-top: 21px;
    padding-top: 5px;
  }

  .sub-title {
    font-size: 46px;

  }

  .container {
    // width: 462px;
    float: left;
    // min-height: 156px;
    padding-left: 2%;
    max-width: 850px;
    width: 85%;

    // h1 {
    //   font-size: 1.8rem;
    // }
  }

  .eldor-sub {
    color: white;
    // font-weight: bold;
    font-family: $font-open-sans;
  }
}

a.read-script-link:hover {
  .read-script {
    background-image: gray;
  }
}

.read-script {
  display: block;
  float: right;
  color: white;
  width: 50px;
  padding-right: 5%;
  height: 105px;
  font-size:14px;
  font-weight: 500;


  .vertical-text {
    // display: inline-block;
    transform: rotate(90deg);
    transform-origin: left top 0;
    width: 105px;
    height: 15px;
    font-size-adjust: 16px;
  }

  img {
    width: 20px;
    height: 55px;
  }
}

.read-script.subscribe {
  width: 129px;
  padding-right: 2%;
  text-transform: uppercase;
  color: #fa7e33;
  font-weight: bold;
}

.book-link {
  color: white;
  font-family: $font-menu-item;
  font-size: 17px;
  letter-spacing: 2px;
  line-height: 80px;
  float: right;
  margin-right: 2%;

  .pt-4 {
    padding-top: 2rem !important;
  }

  .purchase-book {
    display: inline;
  }

  img {
    display: inline-block;
    width: 75px;
  }
}

.bg-home {
  background-color: $bg-marc;
}

.bg-marc {
  background-color: $bg-marc;

  .top-title {
    position: relative;
    padding: 4%;
    background-color: #00000075;
    color: white;
    // margin: 20% 50px;
    margin: 25% 50px 15% 50px;
    max-width: 604px;
    border-radius: 1px;
    font-family: $font-open-sans;
    font-weight: 400;
  }
}

.bg-vivaldi {
  background-color: $bg-vivaldi;
  // background-position: 0 -404px;
}

.bg-script-vivaldi {
  background-color: $bg-vivaldi;
}

.bg-shakespeare {
  background-color: $bg-shakespeare;
}

.bg-mrh {
  background-color: $bg-mrh;
}

.bg-cold {
  background-color: $bg-cold;
}

.bg-juarez {
  background-color: $bg-juarez;
}

.bg-hertzog {
  background-color: $bg-hertzog;
}

.bg-ocracoke {
  background-color: $bg-ocracoke;
}

.bg-whn {
  background-color: $bg-whn;
}

.bg-term {
  background-color: $bg-marc;
}

.bg-other {
  background-color: $bg-marc;
}

h1,
h2 {
  font-family: $font-menu-item;
}

.sub-page {
  height: 69%;
  max-height: 544px;
  width: 7%;

  .carousel-control-prev-icon,
  .carousel-control-next-icon {
    width: 35px;
    height: 35px;
  }

  span.black-bg {
    background-color: black;
    padding: 6px 5px;
    display: inline-block;
    width: 40px;
    height: 70px;
  }
}


/***END Script/Article***/


/*** Background image ****/

main .banner.jumbotron {
  // min-height: 306px;
  max-height: 733px;
}

.jumbotron {
  background-attachment: fixed;
  background-position: 0 0;
  background-repeat: no-repeat;
  // background-size: cover;
  background-size: 100%;
  padding: 0;

  &.bg-marc {
    background-image: url(../../images/marc-home.jpg);
    background-position: 0 1px;
    background-size: cover;
  }

  &.bg-vivaldi {
    background-image: url(../../images/vivaldi-home.jpg);
    // background-position: 0 -570px;
  }

  &.bg-script-vivaldi {
    background-image: url(../../images/vivaldi-home2.jpg);
    background-position: 0 -21px;
  }

  &.bg-shakespeare {
    background-image: url(../../images/shakespeare-home.jpg);
    background-size: cover;
    background-position: 0 -50px;
  }

  &.bg-mrh {
    background-image: url(../../images/mrh-home.jpg);
    // background-size: 85%;
  }

  &.bg-cold {
    background-image: url(../../images/cold-home.jpg);
    background-size: cover;
    background-position: 0px -28px;
  }

  &.bg-juarez {
    background-image: url(../../images/juarez-home.jpg);
    background-position: 0 -60px;
    // background-size: 85%;
  }

  &.bg-hertzog {
    background-image: url(../../images/hertzog-home.jpg);
  }

  &.bg-ocracoke {
    background-image: url(../../images/ocracoke-home.jpg);
  }

  &.bg-whn {
    background-image: url(../../images/whn-home.jpg);
    background-position: 0 -125px;
  }

  &.bg-term {
    background-image: url(../../images/term-home.jpg);
  }
}

article {
  .container {
    max-width: 996px;
  }

  h2 {
    min-height: 80px;
    width: 100%;
    color: #333;
    font-family: $font-menu-item;
    font-size: 52px; 
    line-height: auto;
  }
}

h1.username {
  color: $color-hover;
}

p.youtube, 
img.certificate {
  // display: block;
  margin-left: auto;
  margin-right: auto;
  width: 86%;
  border: 2px solid rgba(250, 127, 51, 0.562);
  -webkit-box-shadow: 5px 10px 8px 5px #fa7e33;
  box-shadow: 4px 7px 14px 1px #fa7e33;
}

p.youtube {
  overflow: hidden;
  padding-bottom: 56.25%;
  position: relative;
  height: 0;
}

p.youtube iframe {
  left: 0;
  top: 0;
  height: 100%;
  width: 100%;
  position: absolute;
}

h3.d8 {
  font-size: 1.3em;
  color: #5997af;
  padding: 14px 0;
  text-shadow: -2px -2px #ffffff;
  text-align: left;
  width: 100%;

}

p.enlarge {
  display: block;
  position: relative; 
  // min-height: 800px; 
}

img.certificate.shrinkToFit {
  width: 130%;
  position: absolute;
  top: 5%;
  left: -145px;
  z-index: 3;
}

