$bg-color: white;
$bg-color-black: #232323;

$menu-footer-button: #04353c;

/**** background for each page and slide caption  ****/

$bg-marc: #5997AF;
$bg-vivaldi: #901F11;
$bg-shakespeare: #285471;
$bg-mrh: #48474C;
$bg-cold: #252C34;
$bg-juarez: #A43E30;
$bg-hertzog: #6C382D;
$bg-ocracoke:#1F0C08;
$bg-whn:#053C54;
$width-80: 100% !important;
$width-100: 100% !important;

/****  font styles  ****/


$font-menu-item: "Goudy Bookletter 1911";
$font-open-sans: 
    // Safari for OS X and iOS (San Francisco)
    -apple-system,
    // Chrome < 56 for OS X (San Francisco)
    BlinkMacSystemFont,
    // Windows
    "Segoe UI",
    // Android
    "Roboto",
    // Basic web fallback
    "Helvetica Neue",
    Arial,
    sans-serif,
    // Emoji fonts
    "Apple Color Emoji",
    "Segoe UI Emoji", 
    "Segoe UI Symbol" ,
    "Microsoft Yahei",
    "STXihei"
    ;
$font-size-menu: 27px;
$color-hover: #5997AF;

/****  effect and animation  ****/

%tranEffect {
    transition: all 500ms cubic-bezier(0.64, 0.04, 0.35, 1);
}


$border-color: #6495ed3b;